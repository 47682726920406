import React from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ExpandMore } from '@mui/icons-material';
import {
  Tooltip,
  Typography,
  Button,
  Chip,
  TableCell,
  TableRow,
  Box,
  Stack,
} from '@mui/material';
import DependencyComponent from '../components/DependencyComponent';
import { Link as RLink } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { BasicTable } from '../components/BasicTable';
import DatadogIcon from '../components/DatadogIcon';
import { nFormatter } from '../shared/data-utils';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

function ObservabilityPage({ service, environment }) {
  const [depEnv, setDepEnv] = React.useState('prod');
  React.useEffect(() => {
    setDepEnv(environment);
  }, [environment]);
  let datadog_host = 'gopuff.datadoghq.com';
  if (service.k8s_cluster == 'deliverytech') {
    datadog_host = 'rideos.datadoghq.com';
  }

  let sparklineData = [];
  if (service.datadog_logs_metrics) {
    sparklineData = service.datadog_logs_metrics.split(',').map((i) => {
      return Number(i);
    });
  }

  return (
    <>
      <BasicTable>
        <TableRow>
          <TableCell>SLO (7d)</TableCell>
          <TableCell>
            {service.slo_7d ? (
              <Tooltip
                title={`Availability SLO for ${service.service_name} service, defined in Datadog.`}
              >
                <Chip
                  sx={{
                    bgcolor: '#ebfaef',
                    color: '#2a7e41',
                  }}
                  style={{
                    fontFamily:
                      'NotoSans,Lucida Grande,Lucida Sans Unicode,sans-serif',
                    fontWeight: 600,
                  }}
                  label={`${Number(service.slo_7d).toFixed(2)}%`}
                />
              </Tooltip>
            ) : (
              'Not defined!'
            )}
          </TableCell>
        </TableRow>
        {service.datadog_logs_metrics && (
          <TableRow>
            <TableCell>Logs</TableCell>
            <TableCell style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                sx={{ float: 'right' }}
                size="small"
                key={service.registry}
                startIcon={<DatadogIcon sx={{ width: 32, height: 32 }} />}
                component={RLink}
                target="_blank"
                to={`//${datadog_host}/logs?query=service%3A${
                  service.service_name
                }&from_ts=${
                  Date.now() - 86400000
                }&to_ts=${Date.now()}&live=true`}
              >
                {service.datadog_logs_metrics?.includes(',')
                  ? nFormatter(
                      service.datadog_logs_metrics.slice(
                        service.datadog_logs_metrics.lastIndexOf(',') + 1
                      ),
                      2
                    )
                  : nFormatter(service.datadog_logs_metrics, 2)}{' '}
                events/day
              </Button>
              <Stack direction="row">
                <Box sx={{ flexGrow: 1 }}>
                  <SparkLineChart data={sparklineData} height={50} width={99} />
                </Box>
                <Box>
                  <Typography sx={{ mx: 1, py: 2, display: 'flex' }}>
                    (7d)
                  </Typography>
                </Box>
              </Stack>
              {service.team == 'sre' && (
                <Button
                  size="small"
                  startIcon={
                    <ManageSearchIcon sx={{ width: 32, height: 32 }} />
                  }
                  component={RLink}
                  target="_blank"
                  to={`//gopuff.grafana.net/explore?orgId=1&left=%7B%22datasource%22:%225mv3saxnk%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22datasource%22:%7B%22type%22:%22loki%22,%22editorMode%22:%22builder%22,%22expr%22:%22%7Bapp%3D%5C%22${service.service_name}%5C%22%7D%20%7C%3D%20%60%60%20%7C%20json%20%7C%20__error__%3D%60%60%22,%22queryType%22:%22range%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D`}
                >
                  Loki
                </Button>
              )}
            </TableCell>
          </TableRow>
        )}
      </BasicTable>
      {(service.upstream?.length > 0 || service.downstream?.length > 0) && (
        <>
          <Accordion elevation={0} disableGutters defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Dependencies</Typography>
            </AccordionSummary>
            <DependencyComponent env={depEnv} service={service} />
          </Accordion>
        </>
      )}
    </>
  );
}

export default ObservabilityPage;
